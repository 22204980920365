@import "variables";

body {
    background-color: #232323;
    color: #FFFFFF;
    font-family: 'Satoshi', Courier, monospace;
    font-size: 16px;
    line-height: 1.5;
    margin: 0 auto;
}

::selection {
    background: #69FF69;
    color: #232323;
}

section {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}

h1 {
    font-weight: 400;
}

svg {
    cursor: not-allowed;
    fill: $osh-white;
}

.container {
    margin: auto;
    padding: 1rem;
    text-align: center;
}

.osh-form {
    background: unset;
    border: unset;
    border-bottom: 1px solid #FFFFFF;
    box-sizing: border-box;
    color: #FFFFFF;
    font-size: 1rem;
    font-family: 'Satoshi', Courier, monospace;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    text-align: center;
    width: 300px;
}

.osh-form::placeholder {
    color: #FFFFFF;
    opacity: 0.5;
}

.osh-form:focus,
.osh-form:focus-visible {
    outline: unset;
}

input.osh-form:-internal-autofill-selected {
    appearance: unset;
    background-color: inherit;
    color: inherit;
}

.osh-submit {
    background: none;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    font-family: 'Satoshi', Courier, monospace;
    font-size: 1rem;
    font-weight: 600;
    outline: inherit;
    padding: 0;
    transition: 0.3s cubic-bezier(0.6, 0.6, 0.6, 1);
}

.osh-submit:hover {
    color: #69FF69;
}
